import React from 'react'
import { graphql } from 'gatsby'
import { useTheme } from '@material-ui/core/styles'
// Local
import Layout from '../layout'
import Seo from '../components/Seo'

const mkstyles = (theme) => ({
  ...theme.typography.body1,
  'h1': theme.typography.h1,
  'h2': theme.typography.h2,
  'h3': theme.typography.h3,
  'h4': theme.typography.h4,
  'h5': theme.typography.h5,
  'h6': theme.typography.h6,
})

const Page = ({
  data,
}) => {
  const { page } = data
  page.author = {
    fullname: 'David Worms'
  }
  page.tags = []
  page.categories = []
  page.tsdate = Date.now()
  const theme = useTheme()
  const styles = mkstyles(theme)
  return (
    <Layout lang={page.lang}>
      <Seo
        lang={page.lang}
        title={page.frontmatter.title}
        description={page.parent.excerpt}
        keywords={page.tags.map(tag => tag.title).join(', ')}
        slug={page.slug}
        ogType={"article"}
        ogPublishedTime={page.tsdate}
        ogAuthor={page.author.fullname}
        ogSection={page.categories.length && page.categories[0].title}
        ogTag={page.tags.map( tag => tag.title)}
      />
      <div
        dangerouslySetInnerHTML={{ __html: page.parent.html }}
        css={styles}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    page: cloudDoc(slug: { eq: $path }) {
      frontmatter {
        title
      }
      lang
      parent {
        ... on MarkdownRemark {
          html
          excerpt(pruneLength: 200)
        }
      }
      slug
    }
  }
`

export default Page;
